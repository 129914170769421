import { ContractFiltersOptions } from "../types";

import { FiltersTypeEnum } from "@/core/libs/list-data/lib/enums";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";

const removeMask = (value: string) => {
  return value?.replace(/\D/g, "");
};

interface GetFiltersConfigProps {
  contractStatusOptions: ContractFiltersOptions[];
  paymentMethodOptions: ContractFiltersOptions[];
  contractOperationsOptions: ContractFiltersOptions[];
  salesTeamsOptions: ContractFiltersOptions[];
  usersFilterOptions: ContractFiltersOptions[];
  loadingDropdownOptions: ContractFiltersOptions[];
}

export const getFiltersConfig = ({
  contractStatusOptions,
  paymentMethodOptions,
  contractOperationsOptions,
  salesTeamsOptions,
  usersFilterOptions,
  loadingDropdownOptions
}: GetFiltersConfigProps) => [
  {
    label: "Código",
    placeholder: "Código do contrato",
    filterQuery: "code",
    type: FiltersTypeEnum.TEXT,
    inputType: "number"
  },
  {
    label: "Nome",
    placeholder: "Nome do cooperado",
    filterQuery: "fullName",
    type: FiltersTypeEnum.TEXT
  },
  {
    label: "Documento",
    placeholder: "CPF/CNPJ",
    filterQuery: "documentNumber",
    type: FiltersTypeEnum.TEXT,
    maskFormatter: cpfCnpjFormatter,
    valueFormatter: removeMask,
    maxLength: 18
  },
  {
    label: "Estado",
    placeholder: "Estado",
    filterQuery: "contractStatus",
    type: FiltersTypeEnum.SELECT,
    options: contractStatusOptions,
    minWidth: "300px"
  },
  {
    label: "Método de Pagamento",
    placeholder: "Método de Pagamento",
    filterQuery: "paymentMethod",
    type: FiltersTypeEnum.SELECT,
    options: paymentMethodOptions,
    minWidth: "220px"
  },
  {
    label: "Operação",
    placeholder: "Operação",
    filterQuery: "operation",
    type: FiltersTypeEnum.SELECT,
    options: contractOperationsOptions ?? loadingDropdownOptions,
    minWidth: "300px"
  },
  {
    label: "Linha",
    filterQuery: "lineNumber",
    type: FiltersTypeEnum.MASK_TEXT,
    mask: "(99) 99999-9999",
    valueFormatter: removeMask
  },
  {
    label: "Número de Série",
    placeholder: "Número de Série",
    filterQuery: FiltersTypeEnum.SERIAL_NUMBER,
    type: FiltersTypeEnum.TEXT,
    inputType: "number"
  },
  {
    label: "Contratos criados entre",
    filterQuery: ["beginsAt", "finishesAt"],
    type: FiltersTypeEnum.DATE_RANGE
  },
  {
    label: "Tipo de listagem",
    filterQuery: "me",
    type: FiltersTypeEnum.SELECT,
    defaultValue: "false",
    options: [
      {
        key: "false",
        text: "Todos os contratos"
      },
      {
        key: "true",
        text: "Apenas os meus contratos"
      }
    ],
    minWidth: "205px"
  },
  {
    label: "Time comercial",
    placeholder: "Time comercial",
    filterQuery: "salesTeam",
    type: FiltersTypeEnum.SELECT,
    options: salesTeamsOptions ?? loadingDropdownOptions,
    inputType: "select"
  },
  {
    label: "Usuário",
    placeholder: "Usuário",
    filterQuery: "userId",
    type: FiltersTypeEnum.SELECT,
    options: usersFilterOptions ?? loadingDropdownOptions,
    inputType: "select",
    minWidth: "200px"
  },
  {
    label: "Status de Tag",
    placeholder: "Status de Tag",
    filterQuery: "canceledTag",
    type: FiltersTypeEnum.SELECT,
    options: [{ key: "true", text: "Cancelada", disabled: false }],
    inputType: "select",
    minWidth: "200px"
  }
];
