import {
  DefaultButton,
  Stack,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton
} from "@fluentui/react";
import { useEffect, useState } from "react";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import { DisplayContractInfo } from "@/components/Contracts/DisplayContractInfo";
import {
  CustomDrawerFooter,
  CustomDrawerHeader
} from "@/components/Shared/CustomDrawer";
import { Loader } from "@/components/Shared/Loader";
import { ShowMessageDiv } from "@/components/Shared/MessageBar";
import {
  useGetPaymentConditions,
  useGetProfile
} from "@/core/libs/api/react-query/index";
import { IMessageBar, messageBarTypes } from "@/core/libs/message-bar";
import { changeContractStatus, getContract } from "@/modules/Contracts/api";
import {
  ContractBadgeColors,
  TranslatedContractCurrentStep
} from "@/modules/Contracts/enums";
import * as S from "@/modules/Contracts/styles";
import { ContractItem, ContractViewProps } from "@/modules/Contracts/types";

enum NavigationTypes {
  NEXT_PAGE = "NEXT_PAGE",
  PREVIOUS_PAGE = "PREVIOUS_PAGE"
}

type NavigationType = keyof typeof NavigationTypes;

export function ViewContract({
  initialContractId,
  totalPages,
  currentContractList,
  currentPage,
  drawerContainerRef,
  listContractRequest,
  closeDrawer
}: ContractViewProps): JSX.Element {
  const [isFetching, setIsFetching] = useState(true);
  const [isReloadingData, setIsReloadingData] = useState(false);
  const [message, setMessage] = useState<IMessageBar | null>(null);
  const [currentContractId, setCurrentContractId] = useState<number | null>(
    null
  );
  const [navigationType, setNavigationType] = useState<NavigationType>(null);
  const [interactionAction, setInteractionAction] = useState<string | null>(
    null
  );
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [hasPreviousContract, setHasPreviousContract] =
    useState<boolean>(false);
  const [hasNextContract, setHasNextContract] = useState<boolean>(false);
  const [contract, setContract] = useState<ContractItem | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const userProfile = useGetProfile();
  const permissions = userProfile.data?.data?.role.permissions;

  function clearMessage() {
    setMessage(null);
  }

  const { data: invoiceInfos } = useGetPaymentConditions({
    contractId: contract?.id
  });

  function handleCloseDrawer() {
    closeDrawer();
  }

  function findFirstPossibleContractId() {
    if (currentContractList) {
      const firstContractFound = currentContractList?.find(
        contract => contract.currentStep.internalName !== "IN_PROGRESS"
      );

      return firstContractFound ? firstContractFound.id : null;
    }

    return null;
  }

  function findLastPossibleContractId() {
    if (currentContractList) {
      const copyContractList = [...currentContractList];
      copyContractList.reverse();

      const lastContractFound = copyContractList.find(
        contract => contract.currentStep.internalName !== "IN_PROGRESS"
      );

      return lastContractFound ? lastContractFound.id : null;
    }

    return null;
  }

  function checkPermissions(contract: ContractItem) {
    if (
      permissions &&
      contract.nextStep &&
      contract.nextStep.interactionType === "ACTION_STATUS" &&
      permissions[PermissionsScope.POS]?.[PermissionsPosModule.CONTRACTS]?.some(
        contractAction =>
          contractAction === PermissionsAction.ALL ||
          contractAction === contract.nextStep.interactionAction
      )
    ) {
      setHasPermission(true);
      setInteractionAction(contract.nextStep.interactionAction);
    } else {
      setHasPermission(false);
    }
  }

  async function getContractRequest(id: string | number) {
    setNavigationType(null);
    setIsFetching(true);

    const response = await getContract(id);

    if (response.error) {
      setMessage({
        message:
          "Não foi possível localizar o contrato, por favor atualize a página e tente novamente",
        type: messageBarTypes.ERROR
      });
    } else {
      const { data: contract } = response;
      const firstContract = findFirstPossibleContractId();
      const lastContract = findLastPossibleContractId();

      if ((firstContract && contract.id !== firstContract) || currentPage > 1) {
        setHasPreviousContract(true);
      } else if (hasPreviousContract) {
        setHasPreviousContract(false);
      }

      if (
        (lastContract && contract.id !== lastContract) ||
        currentPage < totalPages
      ) {
        setHasNextContract(true);
      } else if (hasNextContract) {
        setHasNextContract(false);
      }

      setContract(contract);
      checkPermissions(contract);
      setIsFetching(false);
      setIsReloadingData(false);
    }
  }

  function handleChangeStatusSuccess() {
    setHasPermission(false);
    setMessage({
      message: `${TranslatedContractCurrentStep[
        contract.nextStep.internalName
      ].toUpperCase()} COM SUCESSO!`,
      type: messageBarTypes.SUCCESS
    });

    getContractRequest(currentContractId);
    listContractRequest({ page: Number(currentPage) });
  }

  function handleChangeStatusRequest() {
    changeContractStatus({
      action: interactionAction,
      contractId: currentContractId,
      setIsFetching: setIsReloadingData,
      setMessage,
      helperFunction: handleChangeStatusSuccess
    });
  }

  function showConfirmSubmitAction() {
    setIsDialogOpen(true);
  }

  function handleDialogConfirm() {
    handleChangeStatusRequest();
    setIsDialogOpen(false);
  }

  function handleDialogCancel() {
    setIsDialogOpen(false);
  }

  function previousPageRequest(previousPage: string | number) {
    listContractRequest({
      page: Number(previousPage)
    });
    setNavigationType(NavigationTypes.PREVIOUS_PAGE);
  }

  function nextPageRequest(nextPage: string | number) {
    listContractRequest({
      page: Number(nextPage)
    });
    setNavigationType(NavigationTypes.NEXT_PAGE);
  }

  function previousContract() {
    clearMessage();
    setIsFetching(true);
    const firstId = findFirstPossibleContractId();

    if (firstId === currentContractId) {
      if (currentPage > 1) {
        previousPageRequest(currentPage - 1);

        return;
      } else {
        setMessage({
          message: "Não existem contratos anteriores para navegar.",
          type: messageBarTypes.ERROR
        });
        setIsFetching(false);

        return;
      }
    }

    const copyContractList = [...currentContractList];
    copyContractList.reverse();

    const currentContractIndex = copyContractList.findIndex(
      contract => contract.id === currentContractId
    );

    const currentPreviousContract = copyContractList.find(
      (contract, index) =>
        index > currentContractIndex &&
        contract.currentStep.internalName !== "IN_PROGRESS"
    );

    if (currentPreviousContract) {
      getContractRequest(currentPreviousContract.id);
      setCurrentContractId(currentPreviousContract.id);

      return;
    }
  }

  function nextContract() {
    clearMessage();
    setIsFetching(true);
    const lastIndexId = findLastPossibleContractId();

    if (lastIndexId === currentContractId) {
      if (totalPages > currentPage) {
        nextPageRequest(currentPage + 1);

        return;
      } else {
        setMessage({
          message: "Não existem mais contratos para navegar.",
          type: messageBarTypes.ERROR
        });
        setIsFetching(false);

        return;
      }
    }

    const currentContractIndex = currentContractList.findIndex(
      contract => contract.id === currentContractId
    );

    const currentNextContract = currentContractList.find(
      (contract, index) =>
        index > currentContractIndex &&
        contract.currentStep.internalName !== "IN_PROGRESS"
    );

    if (currentNextContract) {
      getContractRequest(currentNextContract.id);
      setCurrentContractId(currentNextContract.id);

      return;
    }
  }

  useEffect(() => {
    clearMessage();
    setCurrentContractId(initialContractId);
    getContractRequest(initialContractId);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (navigationType === NavigationTypes.PREVIOUS_PAGE && currentPage) {
      const lastContract = findLastPossibleContractId();

      if (lastContract) {
        getContractRequest(lastContract);
        setCurrentContractId(lastContract);
      } else if (currentPage > 1) {
        previousPageRequest(currentPage - 1);
      }
    }

    if (navigationType === NavigationTypes.NEXT_PAGE && currentPage) {
      const firstContract = findFirstPossibleContractId();

      if (firstContract) {
        getContractRequest(firstContract);
        setCurrentContractId(firstContract);
      } else if (currentPage < totalPages) {
        nextPageRequest(currentPage + 1);
      }
    }
    //eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <CustomDrawerHeader
        drawerContainerRef={drawerContainerRef}
        removePreviousTitle
      >
        {!isFetching && (
          <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
            <Stack
              horizontal
              tokens={{ childrenGap: 5 }}
              verticalAlign="center"
            >
              <span>Visualizar Contrato:</span>

              {contract && (
                <>
                  <DefaultButton
                    type="link"
                    title="Ir para contrato"
                    href={`/contracts/${contract.id}`}
                    iconProps={{ iconName: "FolderSearch" }}
                  >
                    <span>{contract.id}</span>
                  </DefaultButton>
                </>
              )}
            </Stack>

            {contract && (
              <S.Badge
                bgColor="transparent"
                display="flex"
                flexGrow="1"
                justifyContent="center"
                wordWrap="break-word"
                whiteSpace="unset"
                fontWeight="600"
                fontColor={
                  ContractBadgeColors[contract.currentStep.internalName] ??
                  ContractBadgeColors.DEFAULT
                }
                border={`1px solid ${
                  ContractBadgeColors[contract.currentStep.internalName] ??
                  ContractBadgeColors.DEFAULT
                }`}
              >
                {contract.nextStep?.friendlyName ??
                  TranslatedContractCurrentStep[
                    contract.currentStep.internalName
                  ]}
              </S.Badge>
            )}
          </Stack>
        )}
      </CustomDrawerHeader>

      {isFetching ? (
        <Loader />
      ) : (
        <Stack tokens={{ childrenGap: 15 }}>
          <DisplayContractInfo
            clientInfoTitle={"Cooperado"}
            contractInfoTitle={"Informações"}
            className="ms-Grid-col ms-sm12 ms-lg6"
            contract={contract}
            refetchContractData={() => getContractRequest(currentContractId)}
            defaultProductCompactMode
            isInsidePanel
            invoiceInfos={invoiceInfos}
          />

          <ShowMessageDiv message={message} cleanMessage={clearMessage} />

          <CustomDrawerFooter drawerContainerRef={drawerContainerRef}>
            <Stack
              horizontal
              disableShrink
              horizontalAlign="space-between"
              tokens={{ childrenGap: 10 }}
            >
              {(hasPreviousContract || hasNextContract || hasPermission) && (
                <Stack horizontal>
                  {hasPreviousContract ? (
                    <DefaultButton
                      disabled={isFetching || isReloadingData}
                      onClick={previousContract}
                    >
                      Anterior
                    </DefaultButton>
                  ) : null}

                  {hasNextContract ? (
                    <DefaultButton
                      disabled={isFetching || isReloadingData}
                      onClick={nextContract}
                    >
                      Próximo
                    </DefaultButton>
                  ) : null}

                  {hasPermission ? (
                    <DefaultButton
                      type="primary"
                      disabled={isFetching || isReloadingData}
                      onClick={showConfirmSubmitAction}
                    >
                      {contract.nextStep.interactionName}
                    </DefaultButton>
                  ) : null}
                </Stack>
              )}

              <DefaultButton
                disabled={isFetching || isReloadingData}
                onClick={handleCloseDrawer}
              >
                {"Fechar"}
              </DefaultButton>
            </Stack>
          </CustomDrawerFooter>

          {isReloadingData && <Loader />}
        </Stack>
      )}
      <Dialog
        hidden={!isDialogOpen}
        onDismiss={handleDialogCancel}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: "Deseja realizar essa operação?",
          subText: "Você tem certeza de que deseja realizar essa operação?",
          showCloseButton: true
        }}
        modalProps={{
          isBlocking: true
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={handleDialogConfirm} text="Confirmar" />
          <DefaultButton onClick={handleDialogCancel} text="Cancelar" />
        </DialogFooter>
      </Dialog>
    </>
  );
}
