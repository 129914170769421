import { ProductsActionsTypes } from "../types/Products.reducer.types";

export function listSuccess({ list }) {
  return {
    type: ProductsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: ProductsActionsTypes.LIST_FAIL
  };
}
export function listRequest(filter = {}, hasFilter = true, isAdmin?) {
  return {
    type: ProductsActionsTypes.LIST_REQUEST,
    payload: { filter, hasFilter, isAdmin }
  };
}
