import { IconButton } from "@fluentui/react";
import {
  ColumnActionsMode,
  DetailsList,
  IColumn,
  IDragDropContext,
  IDragDropEvents,
  Selection,
  SelectionMode
} from "@fluentui/react/lib/DetailsList";
import { Link } from "@fluentui/react/lib/Link";
import { getTheme, mergeStyles } from "@fluentui/react/lib/Styling";
import { useEffect, useState } from "react";

import { SelectFilter } from "../../list-data/lib/Filters";

import { Input } from "./types";

import { LabelWithTooltip } from "@/components/LabelWithTooltip";
import { TableImage } from "@/components/Shared/TableImage";
import { UploadFileInput } from "@/components/Shared/UploadFileInput";

const theme = getTheme();
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight
});

type FileFieldProps = {
  fileConfig: Input;
  values: {
    id: string;
    url: string;
    fileName: string;
    attachmentType?: string;
    order?: number;
  }[];
  label: string;
  required: boolean;
  setFieldValue: (fieldName: string, value: unknown) => void;
  errors?: string;
  touched?: unknown;
};

const orderFiles = (a, b) => {
  return a?.order - b?.order;
};

export function FilesField({
  values,
  label,
  fileConfig,
  required,
  setFieldValue,
  errors,
  touched
}: FileFieldProps): JSX.Element {
  const selection = new Selection();
  let draggedIndex = -1;
  let draggedItem: any | undefined;
  const theme = getTheme();

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (values) {
      const orderedInitialValues = values.sort(orderFiles);
      setSelectedItems(orderedInitialValues);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFieldValue(fileConfig.name, selectedItems);
  }, [selectedItems, setFieldValue, fileConfig.name]);

  const orderColumn = [];

  fileConfig.showOrder &&
    orderColumn.push({
      key: "order",
      name: "Ordem",
      fieldName: "order",
      isRowHeader: true,
      minWidth: 60,
      isPadded: true,
      onRender: item => {
        return (
          <span>
            {selectedItems.findIndex(selected => selected === item) + 1}
          </span>
        );
      }
    });

  const columns = [
    {
      key: "fileName",
      name: "Arquivo",
      fieldName: "fileName",
      isRowHeader: true,
      minWidth: 100,
      maxWidth: fileConfig.showOrder ? 150 : 0,
      data: "string",
      isPadded: true
    },
    ...orderColumn,

    {
      key: "delete",
      name: "delete",
      fieldName: "delete",
      minWidth: 20,
      maxWidth: 20,
      columnActionsMode: ColumnActionsMode.disabled,
      isIconOnly: true,
      onRender: item => {
        return (
          <IconButton
            id="delete-button"
            onClick={() => {
              setSelectedItems(prevProps => {
                return [...prevProps.filter(img => img !== item)];
              });
            }}
            iconProps={{ iconName: "Delete" }}
            style={{ color: "red" }}
            title={fileConfig.deleteButtonDescription}
          />
        );
      }
    }
  ];

  if (fileConfig.isImage) {
    columns.unshift({
      key: "image",
      name: "Imagem",
      fieldName: "image",
      isRowHeader: true,
      minWidth: 100,
      maxWidth: 150,
      data: "string",
      isPadded: true,
      onRender: item => <TableImage imgFile={item} />
    });
  }
  if (fileConfig.attachmentTypeConfig) {
    columns.splice(1, 0, {
      key: "attachmentType",
      name: "Tipo do anexo",
      fieldName: "select",
      isRowHeader: true,
      minWidth: 220,
      maxWidth: 220,
      onRender: item => (
        <SelectFilter
          key="attachmentType"
          filterConfigs={{
            hideLabel: true,
            placeholder: "Tipo do anexo",
            filterQuery: "attachmentType",
            maxWidth: "220px",
            options: fileConfig.attachmentTypeConfig?.options
          }}
          selectedKeys={
            item?.attachmentType
              ? {
                  attachmentType: item?.attachmentType
                }
              : {}
          }
          setSelectedKeys={value => {
            const itemSelected = selectedItems.find(img => img === item);
            Object.assign(itemSelected, {
              attachmentType: value.attachmentType
            });

            setSelectedItems(prevProps => {
              return [...prevProps.filter(img => img !== item), itemSelected];
            });
          }}
          _paginate={() => {
            return;
          }}
        />
      )
    });
  }
  const onRenderItemColumn = (
    item: any,
    index: number,
    column: IColumn
  ): JSX.Element | string => {
    const key = column.key as keyof any;
    if (key === "name") {
      return <Link data-selection-invoke={true}>{item[key]}</Link>;
    }

    return String(item[key]);
  };

  const insertBeforeItem = (item): void => {
    const draggedItems: any = selection.isIndexSelected(draggedIndex)
      ? (selection.getSelection() as [])
      : [draggedItem!];

    const insertIndex = selectedItems.indexOf(item);
    const handleItems = selectedItems.filter(
      itm => draggedItems.indexOf(itm) === -1
    );

    handleItems.splice(insertIndex, 0, ...draggedItems);

    handleItems.forEach((files, index) => (files.order = ++index));
    setSelectedItems(handleItems);
  };

  const getDragDropEvents = (): IDragDropEvents => {
    return {
      canDrop: (
        dropContext?: IDragDropContext,
        dragContext?: IDragDropContext
      ) => {
        return true;
      },
      canDrag: (item?: any) => {
        return true;
      },
      onDragEnter: (item?: any, event?: DragEvent) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass;
      },
      onDragLeave: (item?: any, event?: DragEvent) => {
        return;
      },
      onDrop: (item?: any, event?: DragEvent) => {
        if (draggedItem) {
          insertBeforeItem(item);
        }
      },
      onDragStart: (
        item?: any,
        itemIndex?: number,
        selectedItems?: any[],
        event?: MouseEvent
      ) => {
        draggedItem = item;
        draggedIndex = itemIndex!;
      },
      onDragEnd: (item?: any, event?: DragEvent) => {
        draggedItem = undefined;
        draggedIndex = -1;
      }
    };
  };

  const dragDropEvents = getDragDropEvents();

  return (
    <>
      <LabelWithTooltip
        label={label}
        description={fileConfig.tooltipDescription}
        required={required}
        id={`label-${fileConfig.name}`}
      />
      <div
        style={{
          border:
            errors && touched ? `1px solid ${theme.palette.red}` : `1px solid`,
          borderRadius: "2px",
          marginTop: 0
        }}
      >
        {selectedItems.length > 0 && (
          <DetailsList
            data-testid={`input-${fileConfig.name}`}
            setKey="items"
            items={selectedItems}
            columns={columns}
            selectionMode={SelectionMode.none}
            onRenderItemColumn={onRenderItemColumn}
            dragDropEvents={dragDropEvents}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            styles={{
              headerWrapper: {
                selectors: {
                  ".ms-DetailsHeader": {
                    paddingTop: 0
                  }
                }
              }
            }}
            listProps={{ style: { cursor: "grab" } }}
          />
        )}
        {selectedItems.length <= fileConfig.maxFiles - 1 && (
          <UploadFileInput
            setFiles={setSelectedItems}
            maxFiles={fileConfig.maxFiles}
            currentItemLength={selectedItems.length}
            uploadIconName={fileConfig.uploadIconName}
            uploadTextDescription={fileConfig.uploadTextDescription}
            allowedFileType={fileConfig.allowedFileType}
          />
        )}
        {errors && touched && (
          <div
            style={{
              color: theme.palette.red,
              padding: "8px",
              borderRadius: "4px"
            }}
          >
            Insira ao menos uma imagem válida.
          </div>
        )}
      </div>
    </>
  );
}
