export const filterParamsInObject = (urlParams, params) => {
  if (!urlParams || !params) {
    return {};
  }
  const oldKeys = Object.keys(urlParams);
  const filteredParams = {};

  for (let i = 0; i < oldKeys.length; i++) {
    if (params[oldKeys[i]]) {
      filteredParams[oldKeys[i]] = urlParams[oldKeys[i]];
    }
  }

  return filteredParams;
};
