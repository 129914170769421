import produce from "immer";

import {
  ProductsActionsTypes,
  ProductsReducerActions,
  ProductsState
} from "../types/Products.reducer.types";

const INITIAL_STATE: ProductsState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ProductsReducerActions
): ProductsState {
  return produce(state, draft => {
    switch (action.type) {
      case ProductsActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case ProductsActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
    }
  });
}
