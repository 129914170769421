import { DefaultButton, getTheme, Icon, Stack } from "@fluentui/react";

import { DefaultCardTitle } from "../styles";

import DocumentUploadTable from "./DocumentUploadTable";

import { Card } from "@/components/Shared/Card";

interface ContractDocumentsProps {
  uploads: any;
  canSendDocument?: boolean;
  setIsVisible?: (condition: boolean) => void;
  isSendingDocumentStep: boolean;
  handleContractStatus?: () => void;
}

export function ContractDocuments({
  uploads,
  canSendDocument,
  isSendingDocumentStep,
  setIsVisible
}: ContractDocumentsProps): JSX.Element {
  const theme = getTheme();

  return (
    <Stack verticalFill>
      <DefaultCardTitle>
        <Icon iconName="DocumentSet" style={{ marginRight: 10 }} />
        Documentos
      </DefaultCardTitle>

      <Card borderRadius="1rem" padding="1rem">
        <Stack style={{ height: "100%" }}>
          <Stack.Item styles={{ root: { height: "100%" } }}>
            {uploads?.length > 0 ? (
              <>
                <DocumentUploadTable uploads={uploads} />
                {isSendingDocumentStep && (
                  <Stack
                    tokens={{ padding: 20 }}
                    horizontalAlign="center"
                    verticalAlign="center"
                  ></Stack>
                )}
              </>
            ) : (
              <Stack
                tokens={{ padding: 20 }}
                horizontalAlign="center"
                verticalAlign="center"
                verticalFill
              >
                <Icon
                  iconName="DocumentSearch"
                  style={{ fontSize: 80, color: "#6b6b6b" }}
                />

                <span
                  style={{
                    fontSize: 20,
                    color: "#6b6b6b",
                    textAlign: "center"
                  }}
                >
                  Nenhum documento foi encontrado.
                </span>

                {canSendDocument && setIsVisible && (
                  <DefaultButton
                    id="upload-documents-button"
                    style={{ marginTop: 15 }}
                    onClick={() => setIsVisible(true)}
                    styles={{
                      root: { borderColor: theme.palette.themeDark },
                      flexContainer: { color: theme.palette.themeDark }
                    }}
                  >
                    <Icon
                      iconName="CloudUpload"
                      style={{
                        marginRight: 10,
                        fontSize: 18
                      }}
                    />
                    Anexar documento(s)
                  </DefaultButton>
                )}
              </Stack>
            )}
          </Stack.Item>
        </Stack>
      </Card>
    </Stack>
  );
}
