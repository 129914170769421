import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { listFailure, listSuccess } from "../actions/products";
import { ProductsActionsTypes } from "../types/Products.reducer.types";

import { ProductsService } from "@/core/libs/api";

const abortController = new AbortController();
const { signal } = abortController;

export function* listProducts({ payload }: AnyAction) {
  const { filter, hasFilter, isAdmin } = payload;
  const service = ProductsService(undefined, isAdmin);

  try {
    let params = { query: undefined };

    if (hasFilter) {
      params = { query: { page: 1, limit: 10 } };

      if (filter) {
        params.query = { ...params.query, ...filter };
      }
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listSuccess({ list: response.data }));
  } catch (error) {
    yield put(listFailure());
  }
}

export default all([
  takeLatest(ProductsActionsTypes.LIST_REQUEST, listProducts)
]);
