import { Spinner } from "@fluentui/react";
import { formatDistance } from "date-fns/formatDistance";
import { ptBR } from "date-fns/locale/pt-BR";

import { SelectedOption } from "./enums/SelectedOption.enum";
import { IResponseNotifications } from "./ResponseNotifications.interface";
import { Container } from "./styles";

import { ResponseNotifications } from ".";

import GenericInfiniteScroll from "@/components/InfiniteScroll";

interface ResponseNotificationsContentProps {
  handleCloseBubble: () => void;
  openNotificationsBubbleButtonId: string;
  listOfNotifications?: IResponseNotifications;
  handleReadNotification: (notificationRecipientId: number) => Promise<void>;
  handleReadAllNotifications: () => Promise<void>;
  paginateNotifications: () => object;
  selectedOption: SelectedOption;
  setSelectedOption: (option: SelectedOption) => void;
  isLoading?: boolean;
  isLoadingNextPage?: boolean;
  hasNextPage?: boolean;
}

const ResponseNotificationsContent = ({
  handleCloseBubble,
  openNotificationsBubbleButtonId,
  listOfNotifications,
  handleReadNotification,
  paginateNotifications,
  selectedOption,
  setSelectedOption,
  isLoading,
  isLoadingNextPage,
  hasNextPage,
  handleReadAllNotifications
}: ResponseNotificationsContentProps) => {
  const renderNotifications = () => {
    if (isLoading && !isLoadingNextPage) {
      return (
        <Spinner
          styles={{ root: { padding: "2rem" } }}
          label="Carregando notificações..."
        />
      );
    }

    if (listOfNotifications?.items.length === 0 && !isLoading) {
      return <p>Ainda não há nenhuma notificação...</p>;
    }

    return listOfNotifications?.items.map((notificationRecipient, index) => {
      if (!notificationRecipient) return;

      const createdAt = formatDistance(
        new Date(notificationRecipient.createdAt),
        new Date(),
        { locale: ptBR }
      );

      const notification = notificationRecipient.notification;
      const notificationEventChannel = notification.notificationEventChannel;
      const notificationEvent = notificationEventChannel.notificationEvent;
      const notificationResolvedAt = notification.resolvedAt;
      const recipientReadAt = notificationRecipient.readAt;
      const businessUnitName = notification.businessUnit?.tradingName;
      const actionType = notificationEvent.notificationEventType.actionType;
      const serialNumber = notification.inventory?.serialNumber;

      const isResolved =
        !!notificationResolvedAt ||
        (!notificationResolvedAt && !notificationEventChannel.needResolution);

      const isRead = !!recipientReadAt || (!recipientReadAt && isResolved);

      return (
        <ResponseNotifications.InternalContent
          key={index}
          iconName={actionType}
          keyIndex={index}
          notificationId={notification.contractId || notification.termId}
          serialNumber={serialNumber}
          notificationBusinessUnitName={businessUnitName}
          isRead={isRead}
          isResolved={isResolved}
          notificationCreatedAt={createdAt}
          notificationEventChannelMessage={notificationEventChannel.message}
          notificationEventChannelName={notificationEventChannel.name}
          notificationRecipientId={notificationRecipient.id}
          handleReadNotification={handleReadNotification}
          notificationMessage={notification.message}
        />
      );
    });
  };

  return (
    <ResponseNotifications.Root
      onClose={handleCloseBubble}
      targetButtonId={openNotificationsBubbleButtonId}
    >
      <ResponseNotifications.Header
        title="Notificações"
        handleOptionClick={setSelectedOption}
        selectedOption={selectedOption}
        handleReadAllNotifications={handleReadAllNotifications}
        isLoading={isLoading}
      />

      <GenericInfiniteScroll
        fetchData={paginateNotifications}
        items={listOfNotifications?.items}
        spinnerLabel="Carregando notificações..."
        hasMore={hasNextPage}
        height={"30rem"}
      >
        <Container>{renderNotifications()}</Container>
      </GenericInfiniteScroll>
    </ResponseNotifications.Root>
  );
};

export { ResponseNotificationsContent, SelectedOption };
