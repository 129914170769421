import { MutableRefObject } from "react";
import * as Yup from "yup";

import {
  FeeContext,
  OfferPriceFeeType,
  PaymentStrategy,
  PaymentMethods
} from "./OffersPrices.types";
import { ListOfPricingGroups } from "./PricingGroups.types";
import { ListOfProducts } from "./Products.types";
import { ServicesCategoriesTypesEnum } from "./ServicesCategories.types";

import { CommonListProps } from "@/common/types/ListFromApi.types";
import {
  IAdditionalOfferPrice,
  IAdditionalOffer
} from "@/components/Settings/AdditionalComponents/types";
import { IMessageBar } from "@/core/libs/message-bar";

export enum OfferProgressOrder {
  SERVICE = "1",
  CARRIER = "2",
  PLAN = "3",
  OFFER = "4",
  PRICE = "5"
}

export enum OfferCombo {
  LIGHT = "LIGHT",
  LIGHT_DADOS = "LIGHT_DADOS",
  CONECTIVIDADE_BASICA = "CONECTIVIDADE_BASICA",
  CONECTIVIDADE_AVANCADA = "CONECTIVIDADE_AVANCADA",
  SMART = "SMART",
  TAG = "TAG"
}

export enum OfferComboFriendlyName {
  LIGHT = "LIGHT",
  LIGHT_DADOS = "LIGHT DADOS",
  CONECTIVIDADE_BASICA = "CONECTIVIDADE BÁSICA",
  CONECTIVIDADE_AVANCADA = "CONECTIVIDADE AVANÇADA",
  SMART = "SMART",
  TAG = "TAG"
}

export interface Discount {
  beginsAt: number;
  numberOfMonths: number;
  value: number;
}

export interface BonusData {
  beginsAt: number;
  numberOfMonths: number;
  value: number;
}

export interface Plan {
  id: number;
  name: string;
  service?: Service;
}

export interface Service {
  id: number;
  name: string;
  serviceCategory: ServiceCategory;
}

export interface ServiceCategory {
  id: number;
  name: string;
  type: ServicesCategoriesTypesEnum;
  billerIntegrationId: string;
}

export interface PricingGroup {
  id: number;
  name: string;
}

export interface OfferPrice {
  id: number;
  price: string;
  validBetween: string;
  pricingGroup: PricingGroup;
  salesConditions: number;
}

export interface Offer {
  id: number;
  name: string;
  discount: Discount;
  activationFee: number;
  bonusData: BonusData;
  combo: string;
  plan: PlanOfOfferPrice;
}
export interface PlanOfOfferPrice {
  id: number;
  name: string;
  data: string;
  carrier: Carrier;
  service: Service;
}

export interface Carrier {
  id: number;
  name: string;
}

export interface SalesCondition {
  id: number;
  cashValue: number;
  financedValue: number;
  product: SalesConditionProduct;
}

interface SalesConditionProduct {
  id: number;
  name: string;
  specifications: IProductSpecification;
  manageStock: true;
  brand: IProductBrand;
  image: {
    id: number;
    url: string;
  };
}

export interface Product {
  id: number;
  inStock: number;
  inventories: number;
  manageStock: boolean;
  name: string;
  specifications?: IProductSpecification;
  status: boolean;
  categoryId: number;
  brandId: number;
  ean?: string;
  category: IProductCategory;
  brand: IProductBrand;
  images: IProductImage[];
}

interface IProductImage {
  fileName: string;
  id: number;
  order: number;
  status: boolean;
  url: string;
}

interface IProductBrand {
  id: number;
  name: string;
  status: string;
}

interface IProductCategory {
  id: number;
  status: boolean;
  name: string;
  code: string;
}

export interface IProductSpecification {
  connectivies: Array<Connectivity>;
  color: string;
  memory: Memory;
  battery: Battery;
  os: Os;
  hardware: Hardware;
  screen: Screen;
  physicalProperty: PhysicalProperty;
  sims: Array<Sim>;
}
export interface Connectivity {
  network: number;
}

export interface Memory {
  storage: number;
  ram: number;
  isExpandable: boolean;
  maxStorage: number;
}

export interface Battery {
  capacity: number;
  material: string;
  removable: boolean;
  cameras: Array<Camera>;
}

export interface Os {
  os: string;
  version: string;
}

export interface Hardware {
  processor: string;
  coreNumber: number;
}

export interface Screen {
  size: string;
  resolution: string;
}

export interface PhysicalProperty {
  releaseYear: number;
  weight: number;
  height: string;
  width: string;
  depth: string;
  isWaterResitent: boolean;
  hasWifi: boolean;
  hasGps: boolean;
  hasBluetooth: boolean;
  hasRadio: boolean;
}

export interface Camera {
  side: string;
  resolution: string;
  hasFlash: boolean;
  zoom: number;
}

export interface Sim {
  simType: string;
}

export interface OfferItem {
  id: number;
  name: string;
  discount: Discount;
  activationFee: number;
  bonusData?: BonusData;
  combo: string;
  plan: Plan;
  offerPrices: OfferPrice[];
}

export interface OfferItemFromGet {
  id: number;
  name: string;
  discount: Discount;
  activationFee: number;
  bonusData?: BonusData;
  combo: string;
  plan: PlanOfOfferPrice;
  offerPrices: OfferPriceItem[];
  status: boolean;
  additionalOffer: IAdditionalOffer[];
}

export interface OfferPriceItem {
  id: number;
  price: string;
  validBetween: string;
  offer?: Offer;
  pricingGroup: PricingGroup;
  salesConditions: SalesCondition[];
  additionalOfferPrice: IAdditionalOfferPrice[];
  offerPriceFees: IOfferPriceFees[];
  offerPricePaymentMethod: IOfferPricePaymentMethod[];
  listOfPaymentMethods?: PaymentMethods[];
}
interface IOfferPricePaymentMethod {
  id: number;
  installmentMax: number;
  offerPriceId: number;
  paymentMethodId: number;
  setupChangeType: string;
  setupChangeValue: string;
  status: boolean;
}
export interface IOfferPriceFees {
  id: number;
  status: boolean;
  fee: IFee;
}

export interface IFee {
  id: number;
  context: FeeContext;
  paymentStrategy: PaymentStrategy;
  type: OfferPriceFeeType;
  value: string;
  status: boolean;
}

export interface ListOfOfferPrice extends CommonListProps {
  items: OfferPriceItem[];
}

export interface ListOfOffers extends CommonListProps {
  items?: OfferItem[];
}

export type OffersProps = {
  canCreate: boolean;
  canEdit: boolean;
};

export type OffersViewProps = {
  match: {
    params: {
      id: string;
    };
  };
};

export type PricingGroupProps = {
  offerPrice: OfferPriceItem;
  comboType: string;
  openDrawerAddProduct: (id: number) => void;
  openDrawerEditPricingGroup: (id: number) => void;
  deletePricingGroup: (id: number) => void;
  deleteProductOffer: (id: number) => void;
};

export type DetailedPricingProps = {
  isLoadingList: boolean;
  list: ListOfOfferPrice;
  paginateRequest: () => void;
};

export type CreatePricingGroupsProps = {
  createPricingGroup: (values, { setSubmitting, closePanel }) => void;
  createProduct: (values, { setSubmitting, closePanel }) => void;
  editPricingGroup: (values, { setSubmitting, closePanel }) => void;
  deletePricingGroup: (id: number) => void;
  deleteProductOffer: (id: number) => void;
  offerItems: OfferItemFromGet;
};

export type AddOfferPriceGroupProps = {
  create: (values, { setSubmitting, closePanel }) => void;
  closeDrawerAndMessage: () => void;
  listPricingGroupRequest: () => void;
  dismissMessage: () => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  message: IMessageBar;
  pricingGroupList: ListOfPricingGroups;
  isLoadingPricingGroupList: boolean;
  offerItems: OfferItemFromGet;
};

export type EditOfferPriceGroupProps = {
  edit: (values, { setSubmitting, closePanel }) => void;
  closeDrawerAndMessage: () => void;
  listPricingGroupRequest: () => void;
  dismissMessage: () => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  message: IMessageBar;
  pricingGroupList: ListOfPricingGroups;
  isLoadingPricingGroupList: boolean;
  offerItems: OfferItemFromGet;
  selectedItemId: number;
};

export type AddOfferProductProps = {
  create: (values, { setSubmitting, closePanel }) => void;
  closeDrawerAndMessage: () => void;
  listProductsRequest: () => void;
  dismissMessage: () => void;
  message: IMessageBar;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  productsList: ListOfProducts;
  isLoadingProductsList: boolean;
  selectedItemId: number;
};

export type EditOfferProps = {
  initialValues: OfferItemFromGet;
  validationSchema: Yup.ObjectSchema<any>;
  editOffer: (values, { setSubmitting }) => void;
  deleteOffer: () => void;
  servicesOptions: { key: string; text: string }[];
  carriersOptions: { key: string; text: string }[];
  plansOptions: { key: string; text: string }[];
  operationsOptions: { key: string; text: string }[];
  blockedFields: { [fieldName: string]: boolean };
};
