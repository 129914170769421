import { all } from "redux-saga/effects";

import accounts from "@/modules/Accounts/sagas/accounts";
import contracts from "@/modules/Contracts/sagas/contracts";
import bannersOffers from "@/modules/Offers/sagas/bannersOffers";
import plansOffers from "@/modules/Offers/sagas/plansOffers";
import productsOffers from "@/modules/Offers/sagas/productsOffers";
import tagOffers from "@/modules/Offers/sagas/tagOffers";
import banners from "@/modules/Settings/sagas/banners";
import businessUnits from "@/modules/Settings/sagas/businessUnits";
import carriers from "@/modules/Settings/sagas/carriers";
import contractModels from "@/modules/Settings/sagas/contractModels";
import distributionCenters from "@/modules/Settings/sagas/distributionCenters";
import inventoriesOccurrences from "@/modules/Settings/sagas/inventoriesOccurrences";
import lines from "@/modules/Settings/sagas/lines";
import linesOccurrences from "@/modules/Settings/sagas/linesOccurrences";
import modelsAttachments from "@/modules/Settings/sagas/modelsAttachments";
import modelsGroups from "@/modules/Settings/sagas/modelsGroups";
import offers from "@/modules/Settings/sagas/offers";
import offersPrices from "@/modules/Settings/sagas/offersPrices";
import organizations from "@/modules/Settings/sagas/organizations";
import permissions from "@/modules/Settings/sagas/permissions";
import plans from "@/modules/Settings/sagas/plans";
import pricingGroups from "@/modules/Settings/sagas/pricingGroups";
import products from "@/modules/Settings/sagas/products";
import productsBrands from "@/modules/Settings/sagas/productsBrands";
import productsCategories from "@/modules/Settings/sagas/productsCategories";
import reportCategories from "@/modules/Settings/sagas/reportCategories";
import reports from "@/modules/Settings/sagas/reports";
import roles from "@/modules/Settings/sagas/roles";
import salesTeams from "@/modules/Settings/sagas/salesTeams";
import serviceProviders from "@/modules/Settings/sagas/serviceProviders";
import services from "@/modules/Settings/sagas/services";
import servicesCategories from "@/modules/Settings/sagas/servicesCategories";
import shippingsGroups from "@/modules/Settings/sagas/shippingsGroups";
import shippingsType from "@/modules/Settings/sagas/shippingsType";
import users from "@/modules/Settings/sagas/users";
import profile from "@/modules/User/sagas/profile";

export default function* rootSaga() {
  return yield all([
    accounts,
    contracts,
    profile,
    businessUnits,
    carriers,
    lines,
    linesOccurrences,
    organizations,
    salesTeams,
    plans,
    users,
    shippingsType,
    shippingsGroups,
    contractModels,
    modelsGroups,
    modelsAttachments,
    distributionCenters,
    products,
    productsBrands,
    productsCategories,
    inventoriesOccurrences,
    offersPrices,
    banners,
    pricingGroups,
    services,
    servicesCategories,
    serviceProviders,
    permissions,
    reportCategories,
    reports,
    roles,
    offers,
    bannersOffers,
    tagOffers,
    plansOffers,
    productsOffers
  ]);
}
