import { MutableRefObject } from "react";

import { ListOfPricingGroups } from "./PricingGroups.types";

import {
  CommonListProps,
  FilterParamsType
} from "common/types/ListFromApi.types";
import { IMessageBar } from "core/libs/message-bar";

export interface Plan {
  id?: number;
  name?: string;
  carrier?: Carrier;
}

export interface Carrier {
  id?: number;
  name?: string;
}

export interface PricingGroup {
  id?: number;
  name?: string;
}

export type BannerItem = {
  id?: number;
  name?: string;
  description?: any;
  display?: boolean;
  bannerType?: string;
  link?: string;
  linkBehavior?: string;
  image?: string;
  fileName?: string;
  displayText?: string;
  order?: number;
  validBetween?: string;
  plan?: Plan;
  pricingGroups?: PricingGroup[];
};

export enum BannerTypes {
  LINK = "LINK",
  PLAN = "PLAN",
  NONE = "NONE"
}

export enum BannerTypesTranslated {
  LINK = "Com Link",
  PLAN = "Com Plano de benefícios",
  NONE = "Somente Imagem"
}

export enum BannerLinkOptions {
  SELF = "SELF",
  BLANK = "BLANK"
}

export interface ListOfBanners extends CommonListProps {
  items?: BannerItem[];
}

export type BannersProps = {
  canCreate: boolean;
  canEdit: boolean;
};

export type CreateProps = {
  create: (values, { setSubmitting }, closeDrawer, isAdmin: boolean) => void;
  message: IMessageBar;
  dismissMessage: () => void;
  closeDrawer: () => void;
  listCarriersRequest: () => void;
  listPlansRequest: ({ carrier }: { carrier?: number }) => void;
  listPricingGroupsRequest: (params?: FilterParamsType) => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  isLoadingCarrierList: boolean;
  isLoadingPlansList: boolean;
  isLoadingPricingGroupsList: boolean;
  carriersList: any;
  plansList: any;
  pricingGroupsList: ListOfPricingGroups;
};

export type EditProps = {
  edit: (values, { setSubmitting }, closeDrawer, isAdmin: boolean) => void;
  dismissMessage: () => void;
  closeDrawer: () => void;
  listCarriersRequest: () => void;
  listPlansRequest: ({ carrier }: { carrier?: number }) => void;
  listPricingGroupsRequest: (params?: FilterParamsType) => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  selectedItemId: string;
  message: IMessageBar;
  isLoadingCarrierList: boolean;
  isLoadingPlansList: boolean;
  isLoadingPricingGroupsList: boolean;
  carriersList: any;
  plansList: any;
  pricingGroupsList: ListOfPricingGroups;
};
