import {
  DatePicker,
  DefaultPalette,
  IStackItemStyles,
  Stack
} from "@fluentui/react";
import { useEffect, useState } from "react";

import { FilterComponentProps } from "../types";

import { Space } from "@/components/Space";
import { formatDate } from "@/utils/FormatDate";

const onFormatDate = (date?: Date): string => {
  return !date ? "" : formatDate(date.toString());
};

export function DateRangerPickerFilter({
  setSelectedKeys,
  _paginate,
  selectedKeys,
  itemsPerPage,
  filterConfigs,
  debouncedTerms
}: FilterComponentProps): JSX.Element {
  const [initialDateKey, finalDateKey] = filterConfigs.filterQuery;

  const initialDate = selectedKeys[initialDateKey];
  const finalDate = selectedKeys[finalDateKey];

  const [selectedInitialDate, setSelectedInitialDate] = useState<string | null>(
    null
  );
  const [selectedFinalDate, setSelectedFinalDate] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (initialDate && finalDate) {
      setSelectedInitialDate(initialDate);
      setSelectedFinalDate(finalDate);
    }
  }, [initialDate, finalDate]);

  const formatDate = (date: Date) =>
    date.toLocaleDateString().replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1");
  const sanitizeDate = (date?: string) => date && new Date(`${date}T12:00`);

  function handleInitialDateChange(initialDate: Date) {
    const currentKeys = Object.assign({}, selectedKeys);

    if (initialDate) {
      const date = formatDate(initialDate);
      currentKeys[initialDateKey] = date;

      setSelectedInitialDate(date);
    } else if (selectedInitialDate) {
      delete currentKeys[initialDateKey];

      setSelectedInitialDate(null);
    }

    setSelectedKeys(currentKeys);

    _paginate({
      limit: itemsPerPage ?? 10,
      ...debouncedTerms,
      ...currentKeys
    });
  }

  function handleFinalDateChange(finalDate: Date) {
    const currentKeys = Object.assign({}, selectedKeys);
    if (finalDate) {
      const date = formatDate(finalDate);

      currentKeys[finalDateKey] = date;

      setSelectedFinalDate(date);
    } else if (selectedFinalDate) {
      delete currentKeys[finalDateKey];

      setSelectedFinalDate(null);
    }

    setSelectedKeys(currentKeys);

    _paginate({
      limit: itemsPerPage ?? 10,
      ...debouncedTerms,
      ...currentKeys
    });
  }

  const stackItemStyles: IStackItemStyles = {
    root: {
      alignItems: "center",
      color: DefaultPalette.white,
      display: "flex",
      height: 50,
      justifyContent: "center",
      columnGap: ".5rem"
    }
  };

  return (
    <Space customAlignItems="flex-end">
      <Stack>
        <label htmlFor="" style={{ fontWeight: 600 }}>
          {filterConfigs.label}
        </label>
        <Stack.Item grow={2} styles={stackItemStyles}>
          <DatePicker
            placeholder={"Data Inicial"}
            onSelectDate={handleInitialDateChange}
            value={sanitizeDate(selectedInitialDate)}
            formatDate={onFormatDate}
            isMonthPickerVisible={false}
            allowTextInput
            allFocusable
            styles={{
              statusMessage: { display: "none" }
            }}
            style={{
              display: "flex",
              alignItems: "end",
              borderBlock: "#575756"
            }}
          />
          <DatePicker
            placeholder={"Data Final"}
            onSelectDate={handleFinalDateChange}
            value={sanitizeDate(selectedFinalDate)}
            formatDate={onFormatDate}
            isMonthPickerVisible={false}
            allowTextInput
            minDate={sanitizeDate(selectedInitialDate)}
            styles={{
              statusMessage: { display: "none" }
            }}
            style={{
              display: "flex",
              alignItems: "end"
            }}
          />
        </Stack.Item>
      </Stack>
    </Space>
  );
}
