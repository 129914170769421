import { Shimmer, ShimmerElementType } from "@fluentui/react";

import { IActivationInstallment } from "./interfaces";
import { Scroll, Title, Subtitle } from "./styles";

import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";

interface InvoiceSetupProps {
  setup: IActivationInstallment;
  title?: string;
  isLoading?: boolean;
  isTagService?: boolean;
}

export function InvoiceSetup({
  setup,
  title,
  isLoading = false,
  isTagService = false
}: InvoiceSetupProps) {
  if (isLoading) {
    return (
      <Scroll>
        {Array.from({ length: 3 }).map((_, index) => (
          <Shimmer
            key={index}
            style={{ marginBottom: "0.5rem" }}
            shimmerElements={[
              {
                type: ShimmerElementType.line,
                height: 30
              }
            ]}
          />
        ))}
      </Scroll>
    );
  }

  if (!setup) return null;

  return (
    <Scroll>
      {title && (
        <Title>
          <p>{title}</p>
        </Title>
      )}

      <Subtitle>
        <p>Total da Adesão</p>
        <p>{transformToCurrencyString(setup.monthlyFee)}</p>
      </Subtitle>

      {!isTagService && (
        <Subtitle value={setup.sim}>
          <p>Total do CHIP</p>
          <p className="value">{transformToCurrencyString(setup.sim)}</p>
        </Subtitle>
      )}

      {setup.combo > 0 && (
        <Subtitle>
          <p>Total do Combo</p>
          <p>{transformToCurrencyString(setup.combo)}</p>
        </Subtitle>
      )}

      {setup.others > 0 && (
        <Subtitle>
          <p>Valor do Frete</p>
          <p>{transformToCurrencyString(setup.others)}</p>
        </Subtitle>
      )}

      {setup.activation > 0 && (
        <Subtitle>
          <p>Valor de ativação</p>
          <p>{transformToCurrencyString(setup.activation)}</p>
        </Subtitle>
      )}

      {setup.discount > 0 && (
        <Subtitle value={0 - setup.discount}>
          <p>Desconto Personalizado</p>
          <p className="value">- {transformToCurrencyString(setup.discount)}</p>
        </Subtitle>
      )}

      <Subtitle>
        <strong>Total para Pagar</strong>
        <strong>{transformToCurrencyString(setup.total)}</strong>
      </Subtitle>
    </Scroll>
  );
}
