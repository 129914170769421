import { DefaultButton, IconButton } from "@fluentui/react/lib/Button";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Label } from "@fluentui/react/lib/Label";
import { Stack } from "@fluentui/react/lib/Stack";
import { getTheme } from "@fluentui/react/lib/Styling";
import { MaskedTextField, TextField } from "@fluentui/react/lib/TextField";
import { Field, FieldArray } from "formik";
import { useCallback, useEffect, useState } from "react";

import { IAddressesFieldsProps } from "../lib/interfaces/Addresses-fields.props";

import { updateAddressesFieldsInputs } from "./update-adresses-fields-inputs";

import StripedBackground from "@/components/Shared/StripedBackground";

interface AddressFieldProps {
  configuration: any;
  currentAddress: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  index: any;
  arrayHelpers: any;
  value;
  values: any;
}

function AddressField({
  configuration,
  currentAddress,
  setFieldValue,
  errors,
  touched,
  index,
  arrayHelpers,
  values
}: AddressFieldProps) {
  const [hasRendered, setHasRendered] = useState<boolean>(false);
  const [populatedFields, setPopulatedFields] = useState<object>({});

  const theme = getTheme();

  const findViaCepByItem = useCallback(
    (item, field) => {
      if (item?.match(/\d/g)?.length === 8) {
        setFieldValue(
          `${currentAddress}.${field.name}`,
          item.replace(/\D/g, "")
        );
        fetch(`https://viacep.com.br/ws/${item}/json/`)
          .then(response => {
            return response.json();
          })
          .then(data => {
            if (data.erro === true) return;

            updateAddressesFieldsInputs({
              updateInput: setFieldValue,
              setPopulatedFields,
              data,
              currentAddress
            });
          });
      }
    },
    [currentAddress, setFieldValue]
  );

  return (
    <StripedBackground>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack tokens={{ childrenGap: 5 }} grow>
          <div className="ms-Grid-row" style={{ marginBottom: 15 }}>
            {configuration.fields.map(field => {
              const errorMessage =
                errors[configuration.name] &&
                Array.isArray(errors[configuration.name]) &&
                touched[configuration.name] &&
                Array.isArray(touched[configuration.name])
                  ? errors[configuration.name] &&
                    errors[configuration.name][index] &&
                    errors[configuration.name][index][field.name]
                  : null;
              switch (field.type) {
                case "select":
                  return (
                    <div
                      className={
                        configuration?.removeCentralization
                          ? ""
                          : `ms-Grid-col ms-sm12${
                              field.size ? ` ms-lg${field.size}` : ""
                            }`
                      }
                      key={`${field.name}[${index}]`}
                    >
                      <Field
                        name={`${currentAddress}.${field.name}`}
                        type={field.type}
                        as={Dropdown}
                        onChange={(evt, item) => {
                          setFieldValue(
                            `${currentAddress}.${field.name}`,
                            item.key
                          );
                        }}
                        placeholder={field.label}
                        key={`${currentAddress}.${field.name}`}
                        label={field.label}
                        disabled={field.disabled || false}
                        options={field.options}
                        styles={{ dropdown: { width: "100%" } }}
                        required={field.required || false}
                        errorMessage={errorMessage}
                        defaultSelectedKey={
                          field.value !== "" ? field.value : undefined
                        }
                      />
                    </div>
                  );

                case "zipCode": {
                  const isAddressNewlyCreated =
                    values[0][field.type] &&
                    !hasRendered &&
                    !values[0].updatedAt;

                  if (isAddressNewlyCreated) {
                    setHasRendered(true);
                    findViaCepByItem(values[0][field.type], field);
                  }

                  return (
                    <div
                      className={
                        configuration?.removeCentralization
                          ? ""
                          : `ms-Grid-col ms-sm12${
                              field.size ? ` ms-lg${field.size}` : ""
                            }`
                      }
                      key={`${field.name}[${index}]`}
                    >
                      <Field
                        id={`address-${field.key}-${index}`}
                        name={`${currentAddress}.${field.name}`}
                        type={field.type}
                        as={field.mask ? MaskedTextField : TextField}
                        placeholder={field.label}
                        key={`${currentAddress}.${field.name}`}
                        label={field.label}
                        disabled={field.disabled || false}
                        mask={field.mask}
                        required={field.required || false}
                        errorMessage={errorMessage}
                        onChange={(evt, item) => {
                          findViaCepByItem(item, field);
                        }}
                      />
                    </div>
                  );
                }

                default:
                  return (
                    <div
                      className={
                        configuration?.removeCentralization
                          ? ""
                          : `ms-Grid-col ms-sm12${
                              field.size ? ` ms-lg${field.size}` : ""
                            }`
                      }
                      key={`${field.name}[${index}]`}
                    >
                      <Field
                        id={`address-${field.key}-${index}`}
                        name={`${currentAddress}.${field.name}`}
                        type={field.type}
                        as={field.mask ? MaskedTextField : TextField}
                        placeholder={field.label}
                        key={`${currentAddress}.${field.name}`}
                        label={field.label}
                        disabled={Boolean(populatedFields[field.key])}
                        mask={field.mask}
                        required={field.required || false}
                        errorMessage={errorMessage}
                      />
                    </div>
                  );
              }
            })}
          </div>
        </Stack>

        {configuration.deleteButtonEnabled &&
          values.length > configuration?.minItems && (
            <Stack.Item align="center">
              <IconButton
                iconProps={{ iconName: "Delete" }}
                title="Remover endereço"
                ariaLabel="Remover endereço"
                styles={{
                  root: {
                    color: theme.palette.red
                  },
                  iconHovered: {
                    color: theme.palette.redDark
                  }
                }}
                onClick={() => arrayHelpers.remove(index)}
              />
            </Stack.Item>
          )}
      </Stack>
    </StripedBackground>
  );
}

export function AddressesFields({
  addressConfigurations,
  values,
  errors,
  touched,
  setFieldValue
}: IAddressesFieldsProps): JSX.Element {
  let canAdd = true;
  if (addressConfigurations.limit) {
    canAdd = values?.length < addressConfigurations.limit;
  }

  useEffect(() => {
    if (addressConfigurations.value && values === undefined) {
      setFieldValue(addressConfigurations.name, addressConfigurations.value);
    }
  }, [
    addressConfigurations.name,
    addressConfigurations.value,
    setFieldValue,
    values
  ]);

  return (
    <FieldArray
      name={addressConfigurations.name}
      key={addressConfigurations.name}
      render={arrayHelpers => {
        return (
          <div style={{ marginTop: 10 }}>
            <Label required={addressConfigurations.required || false}>
              {addressConfigurations.label}
            </Label>
            <Stack tokens={{ childrenGap: 10 }}>
              {values instanceof Array &&
                values.map((address, addressIndex) => {
                  return (
                    <AddressField
                      key={addressIndex}
                      configuration={addressConfigurations}
                      currentAddress={`${addressConfigurations.name}[${addressIndex}]`}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      index={addressIndex}
                      arrayHelpers={arrayHelpers}
                      value={address}
                      values={values}
                    />
                  );
                })}
              {addressConfigurations.newAddressButtonEnabled && (
                <div>
                  <DefaultButton
                    type="button"
                    onClick={() => {
                      arrayHelpers.push({
                        zipCode: "",
                        city: "",
                        state: "",
                        street: "",
                        neighborhood: "",
                        number: "",
                        note: ""
                      });
                    }}
                    disabled={!canAdd}
                  >
                    Adicionar novo endereço
                  </DefaultButton>
                </div>
              )}
            </Stack>
          </div>
        );
      }}
    />
  );
}
