import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Stack,
  TextField
} from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { useEffect, useState } from "react";

import { FilterComponentProps } from "../types";

const splitByCommaOrLineBreak = /\n|,/g;

export function TextArrayFilter({
  setSelectedTerms,
  filterConfigs,
  selectedTerms
}: FilterComponentProps): JSX.Element {
  const [textAreaValue, setTextAreaValue] = useState<string>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const filterQuery = selectedTerms[filterConfigs?.filterQuery as string];

  useEffect(() => {
    filterQuery
      ? setTextAreaValue(filterQuery.join("\n"))
      : textAreaValue && setTextAreaValue(null);
  }, [selectedTerms]);

  const toggleVisibleModal = () => setModalIsOpen(prevState => !prevState);

  function handleFilterButton() {
    let splitedValues = textAreaValue
      ?.split(splitByCommaOrLineBreak)
      .filter(Boolean);

    filterConfigs.maskFormatter &&
      (splitedValues = splitedValues.map(filterConfigs.maskFormatter));

    setSelectedTerms(prevState => {
      return {
        ...prevState,
        [filterConfigs.filterQuery as string]: splitedValues
      };
    });

    toggleVisibleModal();
  }

  return (
    <Stack>
      <Stack>
        <p style={{ fontWeight: 600, marginBottom: 0, padding: "5px 0" }}>
          {filterConfigs.label}
        </p>
        <DefaultButton
          disabled={filterConfigs.disabled ? true : false}
          onClick={toggleVisibleModal}
          label={filterConfigs.label}
          title={filterConfigs.boxTitle}
          ariaLabel={filterConfigs.label}
          style={{
            maxHeight: "30px",
            border: "1px solid rgb(96, 94, 92)"
          }}
        >
          {filterQuery
            ? `${filterQuery.length} ${filterConfigs.successDescription}`
            : filterConfigs.placeholder}

          <Icon iconName="CirclePlus" style={{ fontSize: 17, padding: 7 }} />
        </DefaultButton>
      </Stack>

      <Dialog
        hidden={!modalIsOpen}
        onDismiss={() => setModalIsOpen(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: filterConfigs.dialogTitle,
          subText: filterConfigs.dialogSubText,
          isMultiline: true
        }}
        maxWidth={"400px"}
      >
        <TextField
          ariaLabel={filterConfigs.dialogSubText}
          placeholder={filterConfigs.placeholder}
          multiline
          onChange={event => setTextAreaValue(event.currentTarget.value)}
          value={textAreaValue}
          rows={10}
        />

        <DialogFooter>
          <DefaultButton
            onClick={() => setModalIsOpen(false)}
            text="Cancelar"
          />
          <PrimaryButton onClick={handleFilterButton} text="Filtrar" />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
}
