import { MutableRefObject } from "react";

import {
  DistributionCenterItem,
  ListOfDistributionCenters
} from "./DistributionCenters.types";
import { ListOfProducts, ProductSpecification } from "./Products.types";

import { CommonListProps } from "@/common/types/ListFromApi.types";

export type InventoryItem = {
  id?: string;
  status: boolean;
  availability?: string;
  serialType?: string;
  serialNumber?: string;
  internalCode?: string;
  notes?: string;
  canceledAtStartag: string;
  product?: IProduct;
  distributionCenter?: DistributionCenterItem;
  selfSales?: boolean;
  areaCodes?: Array<{ id: number; code: string }>;
};

interface IProduct {
  id: string;
  name: string;
  ean: string;
  manageStock: boolean;
  specifications: ProductSpecification;
}

export interface ListOfInventories extends CommonListProps {
  items?: InventoryItem[];
}

export enum InventoryAvailabilityBadgeStatusEnum {
  RESERVED = "#FF9800",
  WITHDRAWN = "#2196F3",
  AVAILABLE = "#4CAF50",
  PENDING = "#f1c232",
  SOLD = "#F44336"
}

export enum InventoryAvailabilityTranslatedEnum {
  RESERVED = "Reservado",
  WITHDRAWN = "Retirado",
  AVAILABLE = "Disponível",
  PENDING = "Pendente",
  SOLD = "Vendido"
}

export enum InventoryAvailabilityEnum {
  RESERVED = "RESERVED",
  WITHDRAWN = "WITHDRAWN",
  AVAILABLE = "AVAILABLE",
  PENDING = "PENDING",
  SOLD = "SOLD"
}

export enum InventorySerialTypeEnum {
  IMEI = "IMEI",
  ICCID = "ICCID",
  OTHERS = "OTHERS"
}

export enum InventorySerialTypeTranslatedEnum {
  IMEI = "IMEI",
  ICCID = "ICCID",
  OTHERS = "Outros"
}

export type InventoriesProps = {
  canCreate: boolean;
  canEdit: boolean;
  canTransfer: boolean;
};

export type CreateProps = {
  closePanelAndMessage: () => void;
  productsList: ListOfProducts;
  isLoadingDistributionCenterList: boolean;
  distributionCenterList: ListOfDistributionCenters;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
};

export type EditProps = {
  closePanelAndMessage: () => void;
  selectedItemId: string;
  productsList: ListOfProducts;
  isLoadingDistributionCenterList: boolean;
  distributionCenterList: ListOfDistributionCenters;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
};
