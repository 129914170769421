import { SaleCondition } from "@/modules/Offers/types/ProductsOffers.types";
import { PaymentMethod as SetupChangeTypeEnum } from "@/modules/Settings/types/OffersPrices.types";

interface Shipping {
  name: string;
  price: number;
  deliveryTime: number;
}

interface Signature {
  id: string;
  status: boolean;
  name: string;
  type: string;
  auth: string | null;
  notifyPath: string | null;
  contactType: string | null;
  authConfigs: any | null;
}

export interface ApprovalStep {
  id: number;
  order: number;
  internalName: string;
  friendlyName: string;
  interactionAction: string | null;
  interactionName: string | null;
}

interface EletronicSignature {
  id: number;
  status: boolean;
  name: string;
  signerName: string;
  signerEmail: string;
  signerBirthday: string;
  signerDocument: string;
  externalIntegrationUrl: string;
  externalIntegrationSecret: string;
}

interface ServiceModelOptions {
  associationRequired: boolean;
  id: number;
  latePaymentFeeAmount: number;
  latePaymentInterestRate: number;
  latePaymentInterestRateMonthly: number;
  modelId: number;
  serviceId: number;
  serviceModelOptionsGroupId: number;
  serviceProviderId: number;
  status: boolean;
}

interface ContractModel {
  code: string;
  content?: unknown;
  id: number;
  name: string;
  serviceModelOptions: ServiceModelOptions[];
  status: boolean;
}

interface BusinessUnitsSignatures {
  id: number;
  name: string;
  status: boolean;
  signatureId: number;
  businessUnitId: number;
  signature: Signature;
}

interface BusinessUnit {
  id: number;
  status: boolean;
  name: string;
  settings: any;
  isAutonomous: boolean;
  eletronicSignature: EletronicSignature;
  businessUnitsSignatures: BusinessUnitsSignatures[];
}

interface User {
  id: number;
  fullName: string;
  email?: string;
}

export interface Document {
  id: number;
  objectTypeCode: string;
  documentNumber: string;
  documentType: string;
}

interface Address {
  id: number;
  objectTypeCode: string;
  zipCode: string;
  city: string;
  state: string;
  street: string;
  neighborhood: string;
  number: string;
  note: string | null;
}

interface Contact {
  id: number;
  objectTypeCode: string;
  contact: string;
  contactType: string;
  parentTypeCodeId: number;
}

interface Line {
  id: number;
  status: boolean;
  number: string;
  lineStatus: string;
  carrierStatus: string;
  reservedBetween: string;
  aquisitionDate: string;
  type: string;
  isBringYourOwn: boolean;
  organizationId: number;
  carrierId: number;
}

interface Discount {
  value: number;
  beginsAt: number;
  numberOfMonths: number;
}

interface BonusData {
  value: number;
  beginsAt: number;
  numberOfMonths: number;
}

interface Carrier {
  id: number;
  status: boolean;
  name: string;
  logo: string;
}

interface Plan {
  id: number;
  status: boolean;
  name: string;
  description: string;
  voice: string;
  data: string;
  includedServices: string[];
  freeDataApps: string[];
  appsInfos: string[];
  carrier: Carrier;
}

interface Offer {
  id: number;
  status: boolean;
  name: string;
  planId: number;
  discount: Discount;
  activationFee: string;
  fidelityDuration: number;
  fidelityDurationType: string;
  simValue: string;
  bonusData: BonusData;
  combo: string;
  userId: number;
  plan: Plan;
}

interface OfferPrice {
  id: number;
  status: boolean;
  price: string;
  validBetween: string;
  offer: Offer;
  offerPricePaymentMethod: OfferPricePaymentMethod[];
}

interface OfferPricePaymentMethod {
  id: number;
  status: boolean;
  installmentMax: number;
  setupChangeValue: string;
  setupChangeType: SetupChangeTypeEnum;
  offerPriceId: number;
  paymentMethodId: number;
}

interface Operation {
  id: number;
  status: boolean;
  name: string;
  type: string;
  lineStrategy: string;
}

interface ContractItem {
  id: number;
  status: boolean;
  deletedAt: string | null;
  contractId: number;
  operationId: number;
  offerPriceId: number;
  saleConditionId: number | null;
  offerOverride: any | null;
  lineId: number;
  additionalInfo: any;
  children: any[];
  additionalContractItems: any[];
  operation: Operation;
  offerPrice: OfferPrice;
  line: Line;
  inventory: any[];
  saleCondition: SaleCondition | null;
}
interface LegalRepresentative {
  id: number;
  fullName: string;
  email: string;
  document: string;
  rg: string;
  role: string;
  birthDate: string;
  whatsapp: string;
  parentTypeCodeId: number;
}
interface Installment {
  installment: number;
  monthlyFee: number;
  combo: number;
  others: number;
  total: string;
}

interface TotalFee {
  monthlyFeeWithDiscount: string;
}

interface TotalFeeOverrideFields {
  sim: number;
  contractActivationFee: number;
}

interface ActivationInstallment {
  installment: number;
  monthlyFee: number;
  combo: number;
  others: number;
  total: string;
  discount: number;
  exemptSim?: boolean;
}

interface SalesTeam {
  id: number;
  status: boolean;
  name: string;
}

export enum ServiceCategoryType {
  ADHERENCE = "ADHERENCE",
  BROADBAND = "BROADBAND",
  LINK_DEDICATED = "LINK_DEDICATED",
  MDM = "MDM",
  MOBILE_LINE = "MOBILE_LINE",
  MOBILE_LINE_PRE = "MOBILE_LINE_PRE",
  PABX = "PABX",
  TAG = "TAG",
  TRACKER = "TRACKER"
}

interface ServiceCategory {
  id: number;
  status: boolean;
  name: string;
  type: string;
  billerIntegrationId: string;
}

interface Service {
  id: number;
  status: boolean;
  name: string;
  billerIntegrationId: any;
}

export interface ApprovalFlow {
  id: number;
  steps: ApprovalStep[];
}

interface MaritalStatus {
  id: number;
  status: boolean;
  name: string;
  billerIntegrationId: string;
}

export interface Account {
  id: number;
  status: boolean;
  fullName: string;
  maritalStatusId: number;
  businessUnitId: number;
  accountType: string;
  userId: number;
  occupation: string;
  birthDate: string;
  genderStatus: string;
  salesTeamId: number | null;
  maritalStatus: MaritalStatus;
  secondaryDocument: Document;
  addresses: Address[];
  documents: Document[];
  checkingAccounts: any[];
  contacts: Contact[];
  legalRepresentatives: LegalRepresentative[];
  membershipStatus: string;
}

interface PaymentMethod {
  name: string;
}

export interface Contract {
  id: number;
  createdAt: string;
  updatedAt: string;
  contractModel: ContractModel;
  totalFee: TotalFee;
  totalFeeOverride: any | null;
  installment: Installment[];
  shipping: Shipping;
  processedInstallment: any | null;
  flag: string;
  pdfPath: string | null;
  witnesses: any | null;
  snapshot: Contract;
  eletronicSignature: any | null;
  totalFeeOverrideFields: TotalFeeOverrideFields;
  activationInstallment: ActivationInstallment;
  notes: any | null;
  businessUnit: BusinessUnit;
  signature: Signature;
  salesTeam: SalesTeam;
  serviceCategory: ServiceCategory;
  service: Service;
  approvalFlow: ApprovalFlow;
  currentStep: ApprovalStep;
  nextStep: any | null;
  accountAddress: Address;
  accountDocument: Document;
  accountContact: Contact;
  account: Account;
  checkingAccount: any | null;
  user: User;
  uploads: any[];
  status: boolean;
  salesTeamId: number;
  serviceDeliveredAt: string | null;
  integratedBillingSystemAt: string | null;
  signatureId: number;
  items: ContractItem[];
  paymentMethod: PaymentMethod;
  paymentMethodId: number;
}
