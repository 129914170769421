import { useQuery } from "@tanstack/react-query";
import { StringifiableRecord } from "query-string";
import { useCallback } from "react";

import { ExecuteData, useApi } from "../../useApi";

import {
  IGetDistributionCentersResponse,
  IUseGetDistributionCenterProps
} from "./useGetDistributionCenters.types";

import { useAuth } from "@/core/libs/use-auth";

const INVENTORIES_QUERY_TOKEN = "inventories";

export const useGetDistributionCenters = (
  props?: IUseGetDistributionCenterProps
): IGetDistributionCentersResponse => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();

  const paramValues = {
    all: true,
    page: 1,
    limit: props?.params?.limit || 10,
    ...props?.params
  };

  const fetchData = useCallback(
    async (params: StringifiableRecord) => {
      const data: ExecuteData = {
        params,
        url: "admin/inventories/distribution-centers",
        messages: {
          error: "Tivemos um problema ao carregar os centros de distribuição"
        }
      };
      return await execute(data);
    },
    [execute]
  );

  const { data, isFetching } = useQuery({
    queryKey: [INVENTORIES_QUERY_TOKEN, paramValues],
    queryFn: () => fetchData(paramValues),
    enabled: isAuthenticated,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });

  return {
    distributionCentersList: data?.data,
    isLoadingDistributionCentersList: isFetching
  };
};
